import React from 'react';
import { compose, pure, withStateHandlers } from 'recompose';
import { Collapse, CardBody, Card } from 'reactstrap';

import Icon from 'core/ui/Icon';

function RvTypesMobileCollapse({
  collapsed, toggle, handleKeyDown, title, header, body,
}) {
  const buttonProps = {
    onClick: toggle,
    role: 'button',
    tabIndex: '0',
    onKeyDown: handleKeyDown,
  };

  return (
    <div className="rv-types-mobile__wrapper">
      <div className="rv-types-mobile__header" {...!!body && buttonProps}>
        <div className="row">
          <div className="col-9">
            <h5>{title}</h5>
          </div>
          {!!body && (
            <div className="col text-right">
              <Icon size="lg" icon={collapsed ? 'angle-down' : 'angle-up'} />
            </div>
          )}
        </div>
      </div>
      <div className="rv-types-mobile__header-content">{header}</div>
      {!!body && (
        <Collapse isOpen={!collapsed}>
          <Card>
            <CardBody>{body}</CardBody>
          </Card>
        </Collapse>
      )}
    </div>
  );
}

const enhanced = compose(
  pure,
  withStateHandlers(
    () => ({
      collapsed: true,
    }),
    {
      toggle: ({ collapsed }) => () => ({
        collapsed: !collapsed,
      }),
      handleKeyDown: ({ collapsed }) => (e) => {
        if (e.keyCode === 13) {
          return {
            collapsed: !collapsed,
          };
        }

        return {};
      },
    },
  ),
);

export default enhanced(RvTypesMobileCollapse);
