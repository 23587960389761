import React from 'react';

import { Classes } from 'core/helpers/RV';
import ClassTypeIcon from 'search/components/SearchFilters/filters/ClassTypes/ClassTypeIcon';

import RvTypesMobileCollapse from './RvTypesMobileCollapse';
import RvTypesMobileSection from './RvTypesMobileSection';
import RvTypesCta from './RvTypesCta';

const buildTrackingParams = type => ({
  action: 'Rv Types Table Search',
  properties: {
    class: type,
  },
});

function RvTypesTableMobile() {
  const towableHeader = (
    <React.Fragment>
      <p>
        Provides more flexibility with access to a smaller, secondary car for exploring and more
        living space.
      </p>
      <div className="row">
        <div className="col explore-options__image-col">
          <ClassTypeIcon className="explore-options__icon" type={Classes.TRAVEL_TRAILER} />
          <h5>Travel Trailer</h5>
        </div>
        <div className="col explore-options__image-col">
          <ClassTypeIcon className="explore-options__icon" type={Classes.FIFTH_WHEEL} />
          <h5>Fifth Wheel</h5>
        </div>
      </div>
    </React.Fragment>
  );

  const towableBody = (
    <React.Fragment>
      <RvTypesMobileSection title="Highlights">
        <div className="col">
          <ul>
            <li>Great option for more amenities</li>
            <li>Allow for a second car to visit locations nearby</li>
            <li>Come in many sizes</li>
          </ul>
        </div>
        <div className="col">
          <ul>
            <li>Has an overbed hitch</li>
            <li>Can have as many as five slide outs, resembling a home</li>
            <li>The biggest RV footprint</li>
          </ul>
        </div>
      </RvTypesMobileSection>

      <RvTypesMobileSection title="Driving Comfort Level">
        <div className="col">Comfortable towing with a truck or a large SUV</div>
        <div className="col">Comfortable towing with a full-sized pickup</div>
      </RvTypesMobileSection>

      <RvTypesMobileSection title="Mileage">
        <div className="col">10-20 mpg</div>
        <div className="col">10-18 mpg</div>
      </RvTypesMobileSection>

      <RvTypesMobileSection title="Length">
        <div className="col">21-28 feet</div>
        <div className="col">22-44 feet</div>
      </RvTypesMobileSection>

      <RvTypesMobileSection title="Amenities">
        <div className="col">
          There is a wide range of amenities and floorplans depending on size of trailer
        </div>
        <div className="col">
          Full size kitchens with islands and full size master bedrooms are common
        </div>
      </RvTypesMobileSection>

      <RvTypesMobileSection classNames="rv-types-mobile__section--buttons">
        <div className="col-6">
          <RvTypesCta
            href="/rv-rental?rv_class=Travel%20Trailer"
            fieldName="rvClass.Travel Trailer"
            trackingAttributes={buildTrackingParams('Travel Trailer')}
            classNames="btn-left"
          >
            Search <br className="d-block d-sm-none" />
            Travel Trailers
          </RvTypesCta>
        </div>
        <div className="col-6">
          <RvTypesCta
            href="/rv-rental?rv_class=Fifth%20Wheel"
            fieldName="rvClass.Fifth Wheel"
            trackingAttributes={buildTrackingParams('Fifth Wheel')}
            classNames="btn-right"
          >
            Search <br className="d-block d-sm-none" />
            Fifth Wheels
          </RvTypesCta>
        </div>
      </RvTypesMobileSection>
    </React.Fragment>
  );

  const drivableHeader = (
    <React.Fragment>
      <p>
        Provides the best rent-and-go option and allows for simplicity when it comes to setting up
        camp, just shift the RV into park.
      </p>
      <div className="row">
        <div className="col explore-options__image-col">
          <ClassTypeIcon className="explore-options__icon" type={Classes.CLASS_C} />
          <h5>Class C Motorhome</h5>
        </div>
        <div className="col explore-options__image-col">
          <ClassTypeIcon className="explore-options__icon" type={Classes.CLASS_A} />
          <h5>Class A Motorhome</h5>
        </div>
      </div>
    </React.Fragment>
  );

  const drivableBody = (
    <React.Fragment>
      <RvTypesMobileSection title="Highlights">
        <div className="col">
          <ul>
            <li>Can offer the indulgent amenities of a Class A at a lower price</li>
            <li>Vary in size</li>
            <li>The bigger the RV, the lower the gas mileage</li>
          </ul>
        </div>
        <div className="col">
          <ul>
            <li>Can be decked out with full-size amenities (such as an oven or bed) </li>
            <li>Typically the most high-end and luxurious</li>
          </ul>
        </div>
      </RvTypesMobileSection>

      <RvTypesMobileSection title="Driving Comfort Level">
        <div className="col">Comfortable driving a truck</div>
        <div className="col">Comfortable driving a bus</div>
      </RvTypesMobileSection>

      <RvTypesMobileSection title="Mileage">
        <div className="col">8-13 mpg</div>
        <div className="col">6-8 mpg</div>
      </RvTypesMobileSection>

      <RvTypesMobileSection title="Length">
        <div className="col">20-38 feet</div>
        <div className="col">21-41+ feet</div>
      </RvTypesMobileSection>

      <RvTypesMobileSection title="Amenities">
        <div className="col">Can include overhead sleeping space and some full-size features</div>
        <div className="col">
          Often has secondary sleeping spaces like bunk beds and can offer full-size amenities in
          the kitchen, bedroom, and bathroom
        </div>
      </RvTypesMobileSection>

      <RvTypesMobileSection classNames="rv-types-mobile__section--buttons">
        <div className="col-6">
          <RvTypesCta
            href="/rv-rental?rv_class=Class%20C%20Motor%20Home"
            fieldName="rvClass.Class C Motor Home"
            trackingAttributes={buildTrackingParams('Class C')}
            classNames="btn-left"
          >
            Search <br className="d-block d-sm-none" />
            Class C&apos;s
          </RvTypesCta>
        </div>
        <div className="col-6">
          <RvTypesCta
            href="/rv-rental?rv_class=Class%20A%20Motor%20Home"
            fieldName="rvClass.Class A Motor Home"
            trackingAttributes={buildTrackingParams('Class A')}
            classNames="btn-right"
          >
            Search <br className="d-block d-sm-none" />
            Class A&apos;s
          </RvTypesCta>
        </div>
      </RvTypesMobileSection>
    </React.Fragment>
  );

  const deliverable = (
    <React.Fragment>
      <p>
        Prefer to have your RV delivered? Many owners offer delivery as an additional option, either
        to your pickup location or directly to your destination.
      </p>
      <div className="text-center">
        <RvTypesCta
          href="/rv-rental?delivery=true"
          fieldName="delivery"
          trackingAttributes={buildTrackingParams('Deliverable')}
        >
          <b>Search Deliverable RVs</b>
        </RvTypesCta>
      </div>
    </React.Fragment>
  );

  return (
    <div className="rv-types-mobile">
      <RvTypesMobileCollapse
        title="I want to tow my RV"
        header={towableHeader}
        body={towableBody}
      />
      <RvTypesMobileCollapse
        title="I want to drive my RV"
        header={drivableHeader}
        body={drivableBody}
      />
      <RvTypesMobileCollapse title="I want my RV delivered" header={deliverable} />
    </div>
  );
}

export default React.memo(RvTypesTableMobile);
