import React from 'react';
import styled from 'styled-components';
import { Button } from '@rvshare/core-ui';
import withHitchTheme from 'core/withHitchTheme';

const PlanTripButton = styled(Button)`
  display: flex;
  padding: 0 0.5rem;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;

const LearnMoreButton = ({href}) => {
  return (
    <PlanTripButton
      variant="primary"
      href={href}
      as="a"
      data-track="click"
      data-action="Learn More Button Click"
      data-category="Button Click"
      data-label="Home"
      data-event-category="Homepage"
      data-event-action="Learn More"
      data-event-label="Own an RV?"
    >
      Learn More
    </PlanTripButton>
  );
};

export default withHitchTheme(LearnMoreButton);
