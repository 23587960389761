import React from 'react';

import useLocalStorage from 'core/hooks/useLocalStorage';
import { useDevice } from 'core/hooks/useDevice';
import LogoSecondary from 'images/logo-secondary.svg';

import {
  Container,
  LeftColumn,
  RightColumn,
  LogoContainer,
  CloseButton,
  Text,
  GetAppButton,
} from './styles';

const DownloadAppBanner = () => {
  const [bannerDismissed, setIsBannerDismissed] = useLocalStorage('bannerDismissed', false);
  const { isMobile } = useDevice();

  const handleClose = () => setIsBannerDismissed(true);

  if (!isMobile || bannerDismissed) return null;

  return (
    <Container role="dialog" aria-labelledby="app-banner-text">
      <LeftColumn>
        <CloseButton onClick={handleClose} type="button">
          <span className="sr-only">Close Download App Banner</span>
          &times;
        </CloseButton>
        <LogoContainer>
          <img src={LogoSecondary} alt="RVshare Logo" />
        </LogoContainer>
        <Text id="app-banner-text">
          <strong>Use the app</strong>
          <br />
          Get outside faster
        </Text>
      </LeftColumn>
      <RightColumn>
        <GetAppButton
          as="a"
          href="https://rvshare.onelink.me/k5dL/uljwhzfw"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get App
        </GetAppButton>
      </RightColumn>
    </Container>
  );
};

export default DownloadAppBanner;
