import styled from 'styled-components';
import { Button } from 'core/ui';

export const Container = styled.aside`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.grey200};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const LeftColumn = styled(Column)`
  justify-content: flex-start;
`;

export const RightColumn = styled(Column)`
  justify-content: flex-end;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  width: 46px;
  height: 46px;
  flex-shrink: 0;
`;

export const CloseButton = styled.button`
  color: ${({ theme }) => theme.colors.grey600};
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 1.5rem;
  flex-shrink: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.grey800};
  }
`;

export const Text = styled.p`
  margin: 0;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.grey600};
  line-height: 1.4;

  strong {
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.grey800};
  }
`;

export const GetAppButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondaryGreen};
  min-width: 100px;

  &:hover {
    color: white;
    text-decoration: none;
  }
`;