import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { getStateFromDOM } from 'core/utils/client';
import SafeTracking from 'tracking/SafeTracking';
import mediaQuery from 'core/styles/mediaQuery';

import campgroundBackground1 from 'images/home/campground_1.jpg';
import campgroundBackground2 from 'images/home/campground_2.jpg';
import campgroundBackground3 from 'images/home/campground_3.jpg';
import campgroundBackground4 from 'images/home/campground_4.jpg';
import campgroundBackground5 from 'images/home/campground_5.jpg';
import campgroundBackground6 from 'images/home/campground_6.jpg';

const defaultCampgrounds = [
  { title: 'Disney’s Fort Wilderness Resort', slug: 'disneys-fort-wilderness-resort' },
  {
    title: 'Camp Margaritaville RV Resort and Cabana Cabins',
    slug: 'camp-margaritaville-rv-resort-and-cabana-cabins',
  },
  { title: 'Cherrystone Family Camping & RV Resort', slug: 'cherrystone-family-camping-rv-resort' },
  { title: 'Carolina Pines RV Resort', slug: 'carolina-pines-rv-resort' },
  { title: 'Wild Acres RV Resort & Campground', slug: 'wild-acres-rv-resort-campground' },
  {
    title: 'Yogi Bear’s Jellystone Park Hill Country',
    slug: 'yogi-bears-jellystone-park-hill-country',
  },
];

const campgroundBackgrounds = [
  campgroundBackground1,
  campgroundBackground2,
  campgroundBackground3,
  campgroundBackground4,
  campgroundBackground5,
  campgroundBackground6,
];

const CampgroundsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2.5rem;

  ${mediaQuery.medium`
    justify-content: space-around;
    flex-direction: row;
    margin-bottom: 7rem;
  `}
`;

const Campground = styled.a`
  height: 86px;
  position: relative;
  text-align: center;
  align-content: center;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;

  img {
    height: 100%;
    width: 100%;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    color: #fff;
    font-weight: 500;
    font-size: 1.25rem;
  }

  &:link, &:visited, &:hover, &:active {
    color: #fff;
    text-decoration: none;
  }

  ${mediaQuery.small`
    height: 110px;
    flex: 0 0 32.33%;

    span {
      font-size: 1.75rem;
      line-height: 2rem;
      padding: 1rem;
    }
  `}

  ${mediaQuery.betweenMediumLarge`
    height: 110px;
    flex: 0 0 32.33%;

    span {
      font-size: 1.5rem;
      line-height: 2rem;
      padding: 1rem;
    }
  `}
`;

export default function NearbyCampgrounds({ geolocation }) {
  const [campgrounds, setCampgrounds] = useState(defaultCampgrounds);

  useEffect(() => {
    if (!geolocation) {
      return;
    }

    const contentfulTokens = getStateFromDOM('env.contentful_tokens');

    axios({
      method: 'get',
      url: `https://cdn.contentful.com/spaces/${contentfulTokens.space}/environments/${contentfulTokens.environment}/entries`,
      params: {
        access_token: contentfulTokens.access_token,
        content_type: 'campground',
        'fields.geolocation[near]': geolocation,
        select: 'fields.title,fields.slug',
        limit: 30,
      },
    })
      .then(response => {
        // filter out campgrounds with slugs that end in a number because they're usually duplicates
        const filteredCampgrounds = response.data.items.filter(item => {
          const regex = /\d+$/;
          return !regex.test(item.fields.slug);
        });

        const normalizedResponse = filteredCampgrounds.map(item => ({
          title: item.fields.title,
          slug: item.fields.slug,
        }));

        setCampgrounds(normalizedResponse.slice(0, 6));
      })
      .catch(error => {
        console.error('error', error);
      });
  }, []);

  const handleClick = slug => {
    SafeTracking.track('campgroundsClicked', { campground: slug });
  };

  return (
    <div className="container">
      <h3 className="home__heading">
        {geolocation ? 'Nearby Campgrounds & RV Parks' : 'Popular Campgrounds & RV Parks'}
      </h3>
      <CampgroundsWrapper>
        {campgrounds.map((campground, index) => (
          <Campground
            key={campground.slug}
            href={`/campgrounds/${campground.slug}`}
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() => handleClick(campground.slug)}
          >
            <img 
              src={campgroundBackgrounds[index]}
              alt="campground scenery"
              loading="lazy" 
            />
            <span>{campground.title}</span>
          </Campground>
        ))}
      </CampgroundsWrapper>
    </div>
  );
}
