import React from 'react';
import styled from 'styled-components';
import { Bling as GPT } from 'react-gpt';

GPT.enableSingleRequest();

const Container = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.medium`
    justify-content: start;
  `}
`;

export default function ResponsiveAd({ className, path, sizeMapping, targeting, ...props }) {
  return (
    <Container className={className}>
      <GPT
        adUnitPath={path}
        sizeMapping={sizeMapping || [
          { viewport: [0, 0], slot: [300, 250] },
          { viewport: [768, 0], slot: [728, 90] },
          // Request both ad sizes on big screens so server can default to whichever is paid
          { viewport: [992, 0], slot: [[970, 90], [728, 90]] },
        ]}
        targeting={targeting}
        collapseEmptyDiv
        viewableThreshold={0.01}
        {...props}
      />
    </Container>
  );
}
