import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';

import UserPhoto from 'components/UserPhoto';
import { GROUPS as USER_GROUP } from 'core/helpers/User';

import useSetUserId from 'core/hooks/useSetUserId';
import HelpDropdown from './HelpDropdown';
import RVNavButton from './RVNavButton';

function UserNav(props) {
  const { currentUser, showListButton, isHomepage } = props;
  const isOwner = currentUser.group_id === USER_GROUP.OWNER;
  const isAdminOrOwner = (currentUser.group_id === USER_GROUP.OWNER || currentUser.group_id === USER_GROUP.ADMIN);

  useSetUserId(currentUser.user_id);
  return (
    <ul className={`nav align-items-center order-md-3 ml-auto flex-nowrap user-nav ${!isHomepage && 'inside-nav'}`}>
      <li className="nav-item d-none d-md-inline-block">
        <NavLink
          className="d-flex align-items-center"
          href="/dashboard/favorites"
          data-event-category="Global Element"
          data-event-action="Favorites"
          data-event-label="Top Navigation"
          aria-label="favorites"
        >
          Favorites
        </NavLink>
      </li>
      {!isOwner && (
        <li className="nav-item d-none d-md-inline-block">
          <NavLink
            className="d-flex align-items-center"
            href="/dashboard/trips"
            data-event-category="Global Element"
            data-event-action="Trips"
            data-event-label="Top Navigation"
            aria-label="trips"
          >
            Trips
          </NavLink>
        </li>
      )}
      <li className="nav-item d-none d-md-inline-block">
        <NavLink
          className="d-flex align-items-center"
          href="/dashboard/inbox/"
          data-event-category="Global Element"
          data-event-action="Inbox Icon"
          data-event-label="Top Navigation"
          aria-label="inbox"
        >
          Inbox
        </NavLink>
      </li>
      <li className="nav-item dropdown">
        <NavLink
          href="#"
          className="dropdown-toggle user-dropdown-toggle d-flex justify-content-between align-items-center"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          id="dropdown-navbar-user-logged-in"
        >
          <UserPhoto userType={currentUser.group_id} fileName={currentUser.photo} />{' '}
          <span className="ml-1 d-none d-sm-none d-md-inline-block">{currentUser.first_name}</span>
        </NavLink>
        <div className="dropdown-menu">
          <a className="dropdown-item" href="/dashboard/" data-event-category="Global Element" data-event-action="Dashboard" data-event-label="Top Navigation">
            Dashboard
          </a>
          <a className="dropdown-item" href="/users/logout" data-event-category="Global Element" data-event-action="Logout" data-event-label="Top Navigation" data-testid="logout">
            Logout
          </a>
        </div>
      </li>
      <HelpDropdown isAdminOrOwner={isAdminOrOwner} />
      {!!showListButton && (
        <RVNavButton isLoggedIn isOwner={isOwner} />
      )}
    </ul>
  );
}

UserNav.propTypes = {
  currentUser: PropTypes.shape({}),
};

UserNav.defaultProps = {
  currentUser: null,
};

export default UserNav;
