import React from 'react';

import { Classes } from 'core/helpers/RV';
import ClassTypeIcon from 'search/components/SearchFilters/filters/ClassTypes/ClassTypeIcon';

import RvTypesCta from './RvTypesCta';

const buildTrackingParams = type => ({
  action: 'Rv Types Table Search',
  properties: {
    class: type,
  },
});

function RvTypesTableDesktop() {
  return (
    <React.Fragment>
      <table className="explore-options__table">
        <tbody>
          <tr>
            <td className="left-col" rowSpan="2" />
            <th className="text-center" scope="col" colSpan="2">
              <h4>I want to tow my RV</h4>
            </th>
            <th className="text-center light-blue" scope="col" colSpan="2">
              <h4>I want to drive my RV</h4>
            </th>
          </tr>

          <tr>
            <td className="p-4 text-center" colSpan="2">
              <p>
                Provides more flexibility with access to a smaller, secondary car for exploring and
                more living space.
              </p>
              <div className="row">
                <div className="col explore-options__image-col">
                  <h5>Travel Trailer</h5>
                  <ClassTypeIcon className="explore-options__icon" type={Classes.TRAVEL_TRAILER} />
                </div>
                <div className="col explore-options__image-col">
                  <h5>Fifth Wheel</h5>
                  <ClassTypeIcon className="explore-options__icon" type={Classes.FIFTH_WHEEL} />
                </div>
              </div>
            </td>
            <td className="p-4 text-center light-blue" colSpan="2">
              <p>
                Provides the best rent-and-go option and allows for simplicity when it comes to
                setting up camp, just shift the RV into park.
              </p>
              <div className="row">
                <div className="col explore-options__image-col">
                  <h5>Class C Motorhome</h5>
                  <ClassTypeIcon className="explore-options__icon" type={Classes.CLASS_C} />
                </div>
                <div className="col explore-options__image-col">
                  <h5>Class A Motorhome</h5>
                  <ClassTypeIcon className="explore-options__icon" type={Classes.CLASS_A} />
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td className="left-col">Highlights</td>
            <td>
              <ul>
                <li>Great option for more amenities</li>
                <li>Allow for a second car to visit locations nearby</li>
                <li>Come in many sizes</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Has an overbed hitch</li>
                <li>Can have as many as five slide outs, resembling a home</li>
                <li>The biggest RV footprint</li>
              </ul>
            </td>
            <td className="light-blue">
              <ul>
                <li>Can offer the indulgent amenities of a Class A at a lower price</li>
                <li>Vary in size</li>
                <li>The bigger the RV, the lower the gas mileage</li>
              </ul>
            </td>
            <td className="light-blue">
              <ul>
                <li>Can be decked out with full-size amenities (such as an oven or bed) </li>
                <li>Typically the most high-end and luxurious</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td className="left-col">Driving Comfort Level</td>
            <td>Comfortable towing with a truck or a large SUV</td>
            <td>Comfortable towing with a full-sized pickup</td>
            <td className="light-blue">Comfortable driving a truck</td>
            <td className="light-blue">Comfortable driving a bus</td>
          </tr>

          <tr>
            <td className="left-col">Mileage</td>
            <td>10-20 mpg</td>
            <td>10-18 mpg</td>
            <td className="light-blue">8-13 mpg</td>
            <td className="light-blue">6-8 mpg</td>
          </tr>

          <tr>
            <td className="left-col">Length</td>
            <td>21-28 feet</td>
            <td>22-44 feet</td>
            <td className="light-blue">20-38 feet</td>
            <td className="light-blue">21-41+ feet</td>
          </tr>

          <tr className="explore-options__amenities">
            <td className="left-col">Amenities</td>
            <td>
              <div className="explore-options__amenities-wrapper">
                <p>
                  There is a wide range of amenities and floorplans depending on size of trailer
                </p>
                <RvTypesCta
                  href="/rv-rental?rv_class=Travel%20Trailer"
                  fieldName="rvClass.Travel Trailer"
                  trackingAttributes={buildTrackingParams('Travel Trailer')}
                >
                  Search <br className="d-block d-lg-none" />
                  Travel Trailers
                </RvTypesCta>
              </div>
            </td>
            <td>
              <div className="explore-options__amenities-wrapper">
                <p>Full size kitchens with islands and full size master bedrooms are common</p>
                <RvTypesCta
                  href="/rv-rental?rv_class=Fifth%20Wheel"
                  fieldName="rvClass.Fifth Wheel"
                  trackingAttributes={buildTrackingParams('Fifth Wheel')}
                >
                  Search <br className="d-block d-lg-none" />
                  Fifth Wheels
                </RvTypesCta>
              </div>
            </td>
            <td className="light-blue">
              <div className="explore-options__amenities-wrapper">
                <p>Can include overhead sleeping space and some full-size features</p>
                <RvTypesCta
                  href="/rv-rental?rv_class=Class%20C%20Motor%20Home"
                  fieldName="rvClass.Class C Motor Home"
                  trackingAttributes={buildTrackingParams('Class C')}
                >
                  Search <br className="d-block d-lg-none" />
                  Class C&apos;s
                </RvTypesCta>
              </div>
            </td>
            <td className="light-blue">
              <div className="explore-options__amenities-wrapper">
                <p>
                  Often has secondary sleeping spaces like bunk beds and can offer full-size
                  amenities in the kitchen, bedroom, and bathroom
                </p>
                <RvTypesCta
                  href="/rv-rental?rv_class=Class%20A%20Motor%20Home"
                  fieldName="rvClass.Class A Motor Home"
                  trackingAttributes={buildTrackingParams('Class A')}
                >
                  Search <br className="d-block d-lg-none" />
                  Class A&apos;s
                </RvTypesCta>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="explore-options__bottom-row">
        <h4>I want my RV delivered</h4>
        <p>
          Prefer to have your RV delivered? Many owners offer delivery as an additional option,
          either to your pickup location or directly to your destination.
        </p>
        <RvTypesCta
          href="/rv-rental?delivery=true"
          fieldName="delivery"
          trackingAttributes={buildTrackingParams('Deliverable')}
        >
          Search Deliverable RVs
        </RvTypesCta>
      </div>
    </React.Fragment>
  );
}

export default RvTypesTableDesktop;
