import React from 'react';

function RVNavButton(props) {
  const { isOwner, isLoggedIn, isHomepage, tracking } = props;

  const handleClick = () => {
    if (isHomepage) {
      tracking('List Your RV');
    }
  };

  return (
    <li className="nav-item d-none d-md-flex align-items-center">
      {(isLoggedIn && isOwner) || !isLoggedIn ? (
        <a
          className="btn btn-outline-rvshare-3 list-rv-btn"
          href="/list-your-rv"
          onClick={handleClick}
          data-event-category="Global Element"
          data-event-action="List Your RV"
          data-event-label="Top Navigation"
        >
          List Your RV
        </a>
      ) : (
        <a
          className="btn btn-outline-rvshare-3 list-rv-btn"
          href="/rv-rental"
          data-event-category="Global Element"
          data-event-action="Find an RV"
          data-event-label="Top Navigation"
        >
          Find an RV
        </a>
      )}
    </li>
  );
}

export default RVNavButton;
