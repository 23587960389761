import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styled from 'styled-components';

import Icon from 'core/ui/Icon';
import { GROUPS as USER_GROUP } from 'core/helpers/User';

import logoPrimary from 'images/logo_primary.png';
import logoPrimary2x from 'images/logo_primary@2x.png';

import UserNav from './UserNav';
import HelpDropdown from './HelpDropdown';
import RVNavButton from './RVNavButton';
import MobileNavLinks from './MobileNavLinks';
import LoginRegisterListItems from './LoginRegisterListItems';

const Header = styled.header`
  border-bottom: 1px solid #d8d8d8;
  border-bottom-color: none;
  background: #fff;
  width: 100%;

  ${({ theme }) => theme.medium`
    top: 0;
    border-bottom: 0 !important;
    background: ${({ isTransparent }) =>
      isTransparent ? 'linear-gradient(180deg,rgba(0,0,0,0.5) 0%, transparent 100%)' : '#fff'};
  `}

  .navbar {
    z-index: 1000;
    padding: 0.5rem 0;

    .navbar-toggler {
      color: #fff;
      background-color: #35353594;
      padding-right: 0.75rem;
      margin-right: 5px;
    }

    .navbar-collapse {
      background-color: #fff;

      ${({ theme }) => theme.medium`
        background-color: transparent;
      `}
    }

    ${({ theme }) => theme.small`
      .nav:not(.user-nav) .nav-item {
        border-bottom: 1px solid #ccc;
        padding-right: 15px;
        padding-left: 15px;
        text-align: left;
      }
    `}
    .nav-link:not(.list-rv-btn) {
      color: ${({ theme }) => theme.colors.bodyColor};
      padding: 8px 16px;
      font-weight: bold;

      ${({ theme }) => theme.medium`
        color: ${({ isTransparent }) => (isTransparent ? '#fff' : `${theme.colors.bodyColor}`)};
      `}

      &:hover {
        color: ${props => props.theme.colors.primaryBlue} !important;
      }
    }

    .list-rv-btn {
      color: #fff;
      height: 30px;
      padding: 3px 12px;
      background-color: rgba(40, 40, 40, 0.67);
      border: 1px rgba(40, 40, 40, 0.67);
      font-size: 1rem;

      &:hover {
        background-color: rgba(40, 40, 40, 1);
      }
    }
  }
`;

function BootstrapHeader(props) {
  const {
    isLoggedIn,
    currentUser,
    showModal,
    isHomepage,
    isSimpleNav,
    isDashboard,
    isTransparent,
  } = props;
  const isOwner = !!isLoggedIn && currentUser.group_id === USER_GROUP.OWNER;
  const isAdminOrOwner =
    !!isLoggedIn &&
    (currentUser.group_id === USER_GROUP.OWNER || currentUser.group_id === USER_GROUP.ADMIN);

  const logoElement = (
    <img
      alt="RVshare.com"
      src={logoPrimary}
      srcSet={`${logoPrimary}, ${logoPrimary2x} 2x`}
      width="155"
      height="39"
    />
  );

  const openAuthModals = modal => {
    showModal(modal);
  };

  return (
    <Header className="box-shadow border-bottom" isTransparent={isTransparent}>
      <nav className="navbar navbar-light navbar-expand-md">
        <div className="container-fluid">
          <div className="site-header__logo">
            <a
              className="order-md-0"
              href="/"
              title="RVshare Home Page"
              data-track="click"
              data-action="Logo"
              data-category="Link Click"
              data-event-category="Global Element"
              data-event-action="Logo"
              data-event-label="Top Navigation"
              {...(isHomepage && { 'data-label': 'Home' })}
            >
              {logoElement}
            </a>
          </div>
          {!!isLoggedIn && !isSimpleNav && (
            <UserNav
              currentUser={currentUser}
              showListButton={!isDashboard}
              isHomepage={isHomepage}
            />
          )}
          {!isSimpleNav && (
            <button
              className="navbar-toggler collapsed border-0"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <Icon size="lg" icon={['far', 'bars']} />
            </button>
          )}
          {!isSimpleNav && (
            <div
              className="collapse navbar-collapse order-md-2 text-right"
              id="navbarSupportedContent"
            >
              <ul
                className={cx('navbar-nav ml-auto', {
                  'd-sm-block d-md-none': isLoggedIn,
                })}
              >
                {!isLoggedIn && (
                  <LoginRegisterListItems
                    openRegisterModal={() => openAuthModals('Register', 'Sign Up')}
                    openLoginModal={() => openAuthModals('Login', 'Log In')}
                  />
                )}
                <MobileNavLinks isLoggedIn={isLoggedIn} currentUser={currentUser} />
                <HelpDropdown
                  isAdminOrOwner={isAdminOrOwner}
                  isLoggedIn={isLoggedIn}
                  isHomepage={isHomepage}
                />
                <RVNavButton isLoggedIn={isLoggedIn} isOwner={isOwner} isHomepage={isHomepage} />
              </ul>
            </div>
          )}
        </div>
      </nav>
    </Header>
  );
}
BootstrapHeader.propTypes = {
  currentUser: PropTypes.shape({}),
  isLoggedIn: PropTypes.bool,
  showModal: PropTypes.func,
  isHomepage: PropTypes.bool,
  isSimpleNav: PropTypes.bool,
  isDashboard: PropTypes.bool,
  isTransparent: PropTypes.bool,
};
BootstrapHeader.defaultProps = {
  currentUser: null,
  isLoggedIn: false,
  showModal: null,
  isHomepage: false,
  isSimpleNav: false,
  isDashboard: false,
  isTransparent: false,
};
export default React.memo(BootstrapHeader);
