/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { GROUPS as USER_GROUP } from 'core/helpers/User';
import SafeTracking from 'tracking/SafeTracking';

function MobileNavLinks(props) {
  const { currentUser, isLoggedIn } = props;
  const isOwner = currentUser?.group_id === USER_GROUP.OWNER;
  const isAdmin = currentUser?.group_id === USER_GROUP.ADMIN;

  return (
    <>
      <li className="nav-item d-md-none">
        {(isLoggedIn && isOwner) || !isLoggedIn ? (
          <a className="nav-link" href="/list-your-rv" data-event-category="Global Element" data-event-action="List Your RV" data-event-label="Top Navigation">
            List Your RV
          </a>
        ) : (
          <a className="nav-link" href="/rv-rental" data-event-category="Global Element" data-event-action="Find an RV" data-event-label="Top Navigation">
            Find an RV
          </a>
        )}
      </li>
      {isLoggedIn && (
        <>
          <li className="nav-item d-md-none">
            <a className="nav-link" href="/dashboard/favorites" data-event-category="Global Element" data-event-action="Favorites" data-event-label="Top Navigation">
              Favorites
            </a>
          </li>
          {!isOwner && (
            <li className="nav-item d-md-none">
              <a className="nav-link" href="/dashboard/trips" data-event-category="Global Element" data-event-action="Trips" data-event-label="Top Navigation">
                Trips
              </a>
            </li>
          )}
          <li className="nav-item d-md-none">
            <a className="nav-link" href="/dashboard/inbox/" data-event-category="Global Element" data-event-action="Inbox" data-event-label="Top Navigation">
              Inbox
            </a>
          </li>
        </>
      )}
      <li className="nav-item d-md-none">
        <a className="nav-link" href="https://help.rvshare.com/hc/en-us" target="_blank" rel="noopener noreferrer" data-event-category="Global Element" data-event-action="Help Center" data-event-label="Top Navigation">
          Help Center
        </a>
      </li>
      {(isLoggedIn && (isOwner || isAdmin)) && (
        <li className="nav-item d-md-none">
          <a className="nav-link" href="https://owner-toolkit.rvshare.com/" target="_blank" rel="noopener noreferrer" data-event-category="Global Element" data-event-action="Owner Toolkit" data-event-label="Top Navigation">
            Owner Toolkit
          </a>
        </li>
      )}
      <li className="nav-item d-md-none">
        <a className="nav-link" href="https://www.surveymonkey.com/r/feedbackcenter" target="_blank" rel="noopener noreferrer" data-event-category="Global Element" data-event-action="Give Feedback" data-event-label="Top Navigation" onClick={() => SafeTracking.track('Give feedback header click')}>
          Give feedback
        </a>
      </li>
    </>
  );
}

MobileNavLinks.propTypes = {
  currentUser: PropTypes.shape({}),
  isLoggedIn: PropTypes.bool,
};

MobileNavLinks.defaultProps = {
  currentUser: null,
  isLoggedIn: false,
};

export default MobileNavLinks;
