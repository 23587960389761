import React from 'react';

function RvTypesMobileSection({ title, children, classNames }) {
  return (
    <div className={`rv-types-mobile__section ${classNames || ''}`}>
      {title && <div className="rv-types-mobile__section-title">{title}</div>}
      <div className="rv-types-mobile__section-body row">{children}</div>
    </div>
  );
}

export default React.memo(RvTypesMobileSection);
