/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import SafeTracking from 'tracking/SafeTracking';

function HelpDropdown(props) {
  const { isAdminOrOwner, isHomepage, tracking } = props;

  const handleClick = () => {
    if (isHomepage) {
      tracking('Help');
    }
  };

  return (
    <li className="nav-item dropdown d-none d-md-inline-block owner-dropdown">
      <div
        className="nav-link dropdown-toggle d-md-flex align-items-center help-trigger"
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        tabIndex={0}
        onClick={handleClick}
      >
        Help
      </div>
      <div className="dropdown-menu dropdown-menu-right">
        <a
          className="dropdown-item"
          href="https://help.rvshare.com/hc/en-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help Center
        </a>
        {isAdminOrOwner && (
          <a
            className="dropdown-item"
            href="https://owner-toolkit.rvshare.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Owner Toolkit
          </a>
        )}
        <a
          className="dropdown-item"
          href="https://www.surveymonkey.com/r/feedbackcenter"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => SafeTracking.track('Give feedback header click')}
        >
          Give Feedback
        </a>
      </div>
    </li>
  );
}

export default HelpDropdown;
