import 'core/bootstrap';
import 'stylesheets/home.scss';

import LazyLoad from 'vanilla-lazyload';
import { renderContainer, registerModals } from 'core/utils/client';
import DatePickerModal from 'components/DatePicker/DatePickerModal';

import BootstrapHeader from 'components/PageHeader/BootstrapHeader';

import DownloadAppBanner from 'components/DownloadAppBanner';
import Hero from 'home/components/Hero';
import RvTypesTable from 'home/components/RvTypesTable';
import NearbyCampgrounds from 'home/components/NearbyCampgrounds';
import Destinations from 'home/components/Destinations';
import RentalGuarantee from 'components/RentalGuarantee';
import InLineAd from 'components/Ads/InLineAd';
import ResponsiveAd from 'components/Ads/ResponsiveAd';
import LearnMoreButton from '../../components/LearnMoreButton';

renderContainer('DownloadAppBanner', DownloadAppBanner);

renderContainer('Hero', Hero);

renderContainer('Destinations', Destinations);

renderContainer('RvTypesTable', RvTypesTable);

renderContainer('NearbyCampgrounds', NearbyCampgrounds);

renderContainer('RentalGuarantee', RentalGuarantee);

renderContainer('InLineAd', InLineAd);

renderContainer('ResponsiveAd', ResponsiveAd);

renderContainer('BootstrapHeader', BootstrapHeader);

renderContainer('LearnMoreButton', LearnMoreButton);

registerModals({
  DatePicker: DatePickerModal,
});

import(
  /* webpackChunkName: 'FeaturedRvs' */ 'home/components/FeaturedRvs'
).then(({ default: FeaturedRvs }) => renderContainer('FeaturedRvs', FeaturedRvs));

import(
  /* webpackChunkName: 'HowMuchCanYouMake' */ 'home/components/HowMuchCanYouMake'
).then(({ default: HowMuchCanYouMake }) => renderContainer('HowMuchCanYouMake', HowMuchCanYouMake));

(function initLazyLoad() {
  // eslint-disable-next-line no-new
  new LazyLoad({
    elements_selector: 'img, .lazy-image',
  });
})();
