import styled from 'styled-components';
import { Button } from '@rvshare/core-ui';

export const DestinationsContainer = styled.section`
  background-color: ${props => props.theme.colors.lightBlue};
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 2.25rem;
  ${({ theme }) => theme.medium`
    margin-bottom: 2.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  `}
`;

export const Header = styled.h3`
  font-size: 1.375rem;
  font-weight: 600;
  ${({ theme }) => theme.medium`
    font-size: 1.75rem;
    margin-bottom: 1.75rem;
  `}
`;

export const ImageContainer = styled.a`
  width: ${props => props.width};
  position: static;
  transition: all 0.2s ease-in-out;
  padding-right: ${props => (props.paddingRight ? '0.125rem' : 0)};
  padding-left: ${props => (props.paddingLeft ? '0.125rem' : 0)};
  ${({ theme }) => theme.medium`
    padding-right: ${props => (props.paddingRight ? '0.5rem' : 0)};
    padding-left: ${props => (props.paddingLeft ? '0.5rem' : 0)};
    position: relative;
  `}

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
  }

  div {
    color: ${props => props.theme.colors.bodyColor};
    ${({ theme }) => theme.medium`
      color: #fff;
    `}
  }
`;

export const ImageLabel = styled.div`
  position: static;
  top: unset;
  font-size: 1.125rem;
  font-weight: 600;
  text-shadow: none;
  margin-top: 0.5rem;
  ${({ theme }) => theme.medium`
    position: absolute;
    top: 1.5rem;
    font-size: 1.75rem;
    font-weight: 400;
    text-shadow: 0 2px 5px rgba(0,0,0,.9);
    margin-left: 2rem;
  `}
`;

export const NightlyAverage = styled.div`
  position: static;
  line-height: 1.1;
  background-color: unset;
  padding: 0;
  ${({ theme }) => theme.medium`
    position: absolute;
    top: 4.5rem;
    background-color: rgba(53, 70, 83, 0.8);
    padding: 0.5rem 0.5rem;
  `}
  ${({ theme }) => theme.large`
    padding: 0.75rem 1.25rem;
  `}
`;

export const ParksButton = styled(Button)`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  
  &:hover {
    background-color: #00324d;
    text-decoration: none;
    color: #fff;
  }

  ${props => props.theme.large`
    width: auto;
    color: #377ac9;
    font-size: 1.25rem;
    font-weight: normal;

    &, &:hover {
      background: transparent;
      color: ${({ theme }) => theme.colors.primaryBlue};
    }

    &:hover {
      text-decoration: underline;
      color: #377ac9;
    }
  `}
`;