import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';

function LoginRegisterListItems({ openRegisterModal, openLoginModal }) {
  return (
    <>
      <li className="nav-item">
        <NavLink id="registration-modal-trigger" href="#" onClick={openRegisterModal} data-testid="registration-modal-trigger">Sign Up</NavLink>
      </li>
      <li className="nav-item">
        <NavLink id="login-modal-trigger" href="#" onClick={openLoginModal} data-testid="login-modal-trigger">Log In</NavLink>
      </li>
    </>
  );
}

LoginRegisterListItems.propTypes = {
  openRegisterModal: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
};

export default LoginRegisterListItems;
