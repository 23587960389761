import React from 'react';

import { useDevice } from 'core/hooks/useDevice';
import { Flex } from 'core/ui';
import withHitchTheme from 'core/withHitchTheme';

import GrandCanyonSm from 'images/home/destinations/grand_canyon_275w.jpg';
import GrandCanyonMed from 'images/home/destinations/grand_canyon_400w.jpg';
import GrandCanyonLg from 'images/home/destinations/grand_canyon_570w.jpg';

import YosemiteSm from 'images/home/destinations/yosemite_275w.jpg';
import YosemiteMed from 'images/home/destinations/yosemite_400w.jpg';
import YosemiteLg from 'images/home/destinations/yosemite_570w.jpg';

import ArchesSm from 'images/home/destinations/arches_250w.jpg';
import ArchesMed from 'images/home/destinations/arches_310w.jpg';
import ArchesLg from 'images/home/destinations/arches_362w.jpg';

import SequoiaSm from 'images/home/destinations/sequoia_250w.jpg';
import SequoiaMed from 'images/home/destinations/sequoia_310w.jpg';
import SequoiaLg from 'images/home/destinations/sequoia_362w.jpg';

import CongareeSm from 'images/home/destinations/congaree_250w.jpg';
import CongareeMed from 'images/home/destinations/congaree_310w.jpg';
import CongareeLg from 'images/home/destinations/congaree_362w.jpg';

import {
  DestinationsContainer,
  Header,
  ImageContainer,
  ImageLabel,
  NightlyAverage,
  ParksButton,
} from './Destinations.styles';

const IMAGE_HALF_WIDTH = 547;
const IMAGE_HALF_HEIGHT = 292;
const IMAGE_THIRD_WIDTH = 362;
const IMAGE_THIRD_HEIGHT = 294;

const imageSizes = {
  grandCanyon: {
    sm: GrandCanyonSm,
    med: GrandCanyonMed,
    lg: GrandCanyonLg,
  },
  yosemite: {
    sm: YosemiteSm,
    med: YosemiteMed,
    lg: YosemiteLg,
  },
  arches: {
    sm: ArchesSm,
    med: ArchesMed,
    lg: ArchesLg,
  },
  sequoia: {
    sm: SequoiaSm,
    med: SequoiaMed,
    lg: SequoiaLg,
  },
  congaree: {
    sm: CongareeSm,
    med: CongareeMed,
    lg: CongareeLg,
  },
};

function Destinations() {
  const { isMobile, isTablet, isDesktop } = useDevice();

  const getLabelText = parkName => {
    if (isDesktop) return `${parkName} National Park`;
    return `${parkName}`;
  };

  const getRateText = rate => {
    if (isMobile) return `$${rate} per night`;
    return `For an average of $${rate} per night`;
  };

  const imageSrc = imgType => {
    let source = imageSizes[imgType].sm;

    if (isTablet) {
      source = imageSizes[imgType].med;
    } else if (isDesktop) {
      source = imageSizes[imgType].lg;
    }

    return source;
  };

  return (
    <DestinationsContainer data-analytics-module="Destinations our Renters Love">
      <div className="container">
        <Header>Destinations our Renters Love</Header>
        <Flex mb="1.5rem">
          <ImageContainer
            width="50%"
            paddingRight
            href="/national-parks/grand-canyon-national-park"
            target="_blank"
            rel="noopener noreferrer"
            data-track="click"
            data-action="Rental Destination Link Click"
            data-category="Link Click"
            data-label="Home"
            data-destination="Grand Canyon National Park"
            data-event-category="Homepage"
            data-event-action="Grand Canyon National Park"
            data-event-label="Destinations our Renters Love"
          >
            <img
              src={imageSrc('grandCanyon')}
              alt="Grand Canyon National Park"
              className="lazy-image"
              loading="lazy"
              width={IMAGE_HALF_WIDTH}
              height={IMAGE_HALF_HEIGHT}
            />
            <ImageLabel>{getLabelText('Grand Canyon')}</ImageLabel>
            <NightlyAverage>{getRateText(130)}</NightlyAverage>
          </ImageContainer>
          <ImageContainer
            width="50%"
            paddingLeft
            href="/national-parks/yosemite-national-park"
            target="_blank"
            rel="noopener noreferrer"
            data-track="click"
            data-action="Rental Destination Link Click"
            data-category="Link Click"
            data-label="Home"
            data-destination="Yosemite National Park"
            data-event-category="Homepage"
            data-event-action="Yosemite National Park"
            data-event-label="Destinations our Renters Love"
          >
            <img
              src={imageSrc('yosemite')}
              alt="Yosemite National Park"
              className="lazy-image"
              loading="lazy"
              width={IMAGE_HALF_WIDTH}
              height={IMAGE_HALF_HEIGHT}
            />
            <ImageLabel>{getLabelText('Yosemite')}</ImageLabel>
            <NightlyAverage>{getRateText(146)}</NightlyAverage>
          </ImageContainer>
        </Flex>
        <Flex>
          <ImageContainer
            width="33.33%"
            paddingRight
            href="/national-parks/arches-national-park"
            target="_blank"
            rel="noopener noreferrer"
            data-track="click"
            data-action="Rental Destination Link Click"
            data-category="Link Click"
            data-label="Home"
            data-destination="Arches National Park"
            data-event-category="Homepage"
            data-event-action="Arches National Park"
            data-event-label="Destinations our Renters Love"
          >
            <img
              src={imageSrc('arches')}
              alt="Arches National Park"
              className="lazy-image"
              loading="lazy"
              width={IMAGE_THIRD_WIDTH}
              height={IMAGE_THIRD_HEIGHT}
            />
            <ImageLabel>{getLabelText('Arches')}</ImageLabel>
            <NightlyAverage>{getRateText(141)}</NightlyAverage>
          </ImageContainer>
          <ImageContainer
            width="33.33%"
            paddingRight
            href="/national-parks/sequoia-national-park"
            target="_blank"
            rel="noopener noreferrer"
            data-track="click"
            data-action="Rental Destination Link Click"
            data-category="Link Click"
            data-label="Home"
            data-destination="Sequoia National Park"
            data-event-category="Homepage"
            data-event-action="Sequoia National Park"
            data-event-label="Destinations our Renters Love"
          >
            <img
              src={imageSrc('sequoia')}
              alt="Sequoia National Park"
              className="lazy-image"
              loading="lazy"
              width={IMAGE_THIRD_WIDTH}
              height={IMAGE_THIRD_HEIGHT}
            />
            <ImageLabel>{getLabelText('Sequoia')}</ImageLabel>
            <NightlyAverage>{getRateText(146)}</NightlyAverage>
          </ImageContainer>
          <ImageContainer
            width="33.33%"
            paddingRight
            href="/national-parks/congaree-national-park"
            target="_blank"
            rel="noopener noreferrer"
            data-track="click"
            data-action="Rental Destination Link Click"
            data-category="Link Click"
            data-label="Home"
            data-destination="Congaree National Park"
            data-event-category="Homepage"
            data-event-action="Congaree National Park"
            data-event-label="Destinations our Renters Love"
          >
            <img
              src={imageSrc('congaree')}
              alt="Arches National Park"
              className="lazy-image"
              loading="lazy"
              width={IMAGE_THIRD_WIDTH}
              height={IMAGE_THIRD_HEIGHT}
            />
            <ImageLabel>{getLabelText('Congaree')}</ImageLabel>
            <NightlyAverage>{getRateText(133)}</NightlyAverage>
          </ImageContainer>
        </Flex>
        <Flex justifyContent="flex-end">
          <ParksButton
            variant="primary"
            as="a"
            href="/national-parks"
            target="_blank"
            rel="noopener noreferrer"
          >
            View all National Parks
          </ParksButton>
        </Flex>
      </div>
    </DestinationsContainer>
  );
}

export default withHitchTheme(Destinations);
