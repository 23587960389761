import React from 'react';
import PropTypes from 'prop-types';

import { trackClick } from 'tracking/utils';

import RvTypesContext from './RvTypesContext';

class RvTypesCta extends React.PureComponent {
  handleClick = (event) => {
    const { trackingAttributes, fieldName } = this.props;
    const { action, properties } = trackingAttributes;

    const segmentDataAttributes = {
      action: `${this.context.pageName} page ${action}`,
      properties,
    };

    trackClick(segmentDataAttributes);

    if (this.context.handleFilterUpdate) {
      event.preventDefault();
      this.context.handleFilterUpdate(fieldName);
    }
  };

  static contextType = RvTypesContext;

  render() {
    const {
      children, href, trackingAttributes, style, classNames,
    } = this.props;

    return (
      <a
        className={`btn btn-outline-rvshare-2 ${classNames || ''}`}
        href={href}
        onClick={trackingAttributes && this.handleClick}
        style={style}
        data-event-category="Homepage"
        data-event-action={children}
        data-event-label="Which RV type is right for me?"

      >
        {children}
      </a>
    );
  }
}

RvTypesCta.defaultProps = {
  trackingAttributes: null,
  style: null,
  classNames: null,
};

RvTypesCta.propTypes = {
  children: PropTypes.any.isRequired,
  href: PropTypes.string.isRequired,
  trackingAttributes: PropTypes.shape({}),
  style: PropTypes.shape({}),
  fieldName: PropTypes.string.isRequired,
  classNames: PropTypes.string,
};

export default RvTypesCta;
