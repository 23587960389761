import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveRender from 'components/ResponsiveRender';

import Desktop from './RvTypesTableDesktop';
import Mobile from './RvTypesTableMobile';
import RvTypesContext from './RvTypesContext';

function RvTypesTable(props) {
  const { handleFilterUpdate, page } = props;

  const pageName = page || 'Home';

  const context = { handleFilterUpdate, pageName };

  return (
    <RvTypesContext.Provider value={context}>
      <section className="explore-options" data-analytics-module="Which RV type is right for me?">
        <div className="container">
          <h3 className="home__heading">Which RV type is right for me?</h3>

          <ResponsiveRender>
            {isMobile =>
              isMobile ? (
                <Mobile />
              ) : (
                <div className="explore-options__table-wrapper">
                  <Desktop />
                </div>
              )
            }
          </ResponsiveRender>
        </div>
      </section>
    </RvTypesContext.Provider>
  );
}

RvTypesTable.propTypes = {
  handleFilterUpdate: PropTypes.func,
  page: PropTypes.string,
};

RvTypesTable.defaultProps = {
  handleFilterUpdate: null,
  page: null,
};

export default RvTypesTable;
